import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby';

import Layout from '../components/Layout'
import TVBHeader from '../components/TVBHeader'
import KwaliteitLabel from '../components/KwaliteitLabel'
// import Fotos from '../components/Fotos';

import tomaten from '../../static/img/tomaten.jpg'
// import nancy from '../../static/img/team-nancy.jpg'
// import johan from '../../static/img/team-johan.jpg'
import johanEnNancy from '../../static/img/team-johan-nancy.jpg'
import sanne from '../../static/img/team-sanne.jpg'
import florin from '../../static/img/team-florin.jpg'

// import daniel from '../../static/img/team-daniel.png'
// import question from '../../static/img/team-question.jpg'
// import homeBackGround from '../../static/img/home-background.jpg'
import flandria from '../../static/img/flandria.png'
// import belorta from '../../static/img/belorta.png'
import globalgap from '../../static/img/globalgap.jpg'
import {getUrlFromImage} from '../components/Gallery';
import YoutubeBackground from '../components/Youtube';
import Glide from '@glidejs/glide'


export class IndexPageTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isLightBoxOpen: false, currentImage: 0};
    }

    componentDidMount() {
        new Glide('.glide', {
            autoplay: 5000,
            type: 'carousel',
            animationDuration: 1000
        }).mount();
    }

    render() {
        const years = new Date().getFullYear() - 1993;
        return (
            <div>

                <TVBHeader title="Tuinbouwbedrijf Van Bulck" subtitle="Een passie voor tomaten"
                           background={getUrlFromImage(this.props.titleImage)}/>
                <section className="container has-text-centered">
                    <div className="tvb-subheader">
                        <div className="columns is-mobile" style={{width: '100%'}}>
                            <div className="column" style={{
                                paddingLeft: 50,
                                paddingRight: 50,
                                paddingTop: 28,
                                borderRight: '0.5px solid rgba(128, 128, 128, 0.2)'
                            }}>
                                <h5 style={{color: '#0B3D2C', fontSize: '24px'}}>Product</h5>
                                <p style={{color: '#0B3D2C', fontSize: '16px', margin: '20px auto'}}>
                                    Wij zijn één van de grootste
                                    telers van vleestomaten gevestigd te
                                    Putte, België</p>
                                {/* <a style={{color: '#2A7D2E', fontSize: '16px', textDecoration: 'underline'}} href="">Lees
                                    meer</a> */}
                            </div>
                            <div className="column" style={{paddingLeft: 50, paddingRight: 50, paddingTop: 28}}>
                            
                                <h5 style={{color: '#0B3D2C', fontSize: '24px'}}>Kwaliteit</h5>
                                <p style={{color: '#0B3D2C', fontSize: '16px', margin: '20px auto'}}>
                                    Kwaliteitslabels als Flandria en Global G.A.P.
                                    garanderen de topkwaliteit die wij dagelijks leveren
                                </p>
                                {/* <a style={{color: '#2A7D2E', fontSize: '16px', textDecoration: 'underline'}} href="">Lees
                                    meer</a> */}
                            </div>
                            <div className="column" style={{
                                paddingLeft: 50,
                                paddingRight: 50,
                                paddingTop: 28,
                                borderLeft: '0.5px solid rgba(128, 128, 128, 0.2)'
                            }}>
                                <h5 style={{color: '#0B3D2C', fontSize: '24px'}}>Milieu</h5>
                                <p style={{color: '#0B3D2C', fontSize: '16px', margin: '20px auto'}}>
                                    Ecologie en milieuvriendelijkheid vormen de rode draad in onze bedrijfsfilosofie
                                </p>
                                {/* <a style={{color: '#2A7D2E', fontSize: '16px', textDecoration: 'underline'}} href="">Lees
                                    meer</a> */}
                            </div>
                            
                        </div>
                    </div>
                </section>
                <section className="section home-title">
                    <div className="container has-text-centered">
                        <h1 className="title">
                            Trots op ons product! <span role="img" aria-label="tomaat">🍅</span>
                        </h1>
                    </div>
                </section>
                <section className="section">
                    <div className="container has-text-centered">
                    <div className="columns tvb-info">
                        <div className="column">
                            <div className="amount">{years}</div>
                            <div className="of">Jaar</div>
                            <div className="subtext">Ervaring</div>
                            <div className="info">In het kweken van vleestomaten</div>
                        </div>
                        <div className="column">
                            <div className="amount">6</div>
                            <div className="of">Hectare</div>
                            <div className="subtext">Serres</div>
                            <div className="info">Met hybride belichting</div>
                        </div>
                        <div className="column">
                            <div className="amount">35</div>
                            <div className="of">Medewerkers</div>
                            <div className="subtext">Die instaan voor</div>
                            <div className="info">Het succesvol afleveren van ons product</div>
                        </div>
                    </div>
                    </div>
                    
                </section>
                <section className="hero is-success is-medium tvb-rebelski-tomaat"
                         style={{background: 'url(' + tomaten + ') center center / cover no-repeat'}}>
                    <div className="hero-body has-text-centered">
                        <div className="container">
                            <h1 className="title" style={{fontWeight: 700}}>De Falkland vleestomaat</h1>
                            <h2 className="subtitle">Mooist geribde tomaat</h2>
                            <h2 className="subtitle">Dieprode interne en externe kleur</h2>
                            <h2 className="subtitle">Constante & betrouwbare kwaliteit</h2>
                            <h2 className="subtitle">Goede houdbaarheid</h2>
                        </div>
                    </div>
                </section>
                <section className="section tvb-team">
                    <div className="container has-text-centered">
                        <h1 className="title">Ons team</h1>
                        <div className="columns has-text-centered" style={{marginBottom: 20}}>
                            <div className="column">
                                <div className="tvb-team-member">
                                    <div className="image" style={{
                                        height: 322,
                                        background: 'url(' + johanEnNancy + ') center center / cover no-repeat'
                                    }}></div>
                                    <div className="name">Johan & Nancy</div>
                                    <div className="info">Bestuurders</div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="tvb-team-member">
                                    <div className="image" style={{
                                        height: 322,
                                        background: 'url(' + sanne + ') center center / cover no-repeat'
                                    }}></div>
                                    <div className="name">Sanne</div>
                                    <div className="info">Bedrijfsleider<br/>sorteren & biologie</div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="tvb-team-member">
                                    <div className="image" style={{
                                        height: 322,
                                        background: 'url(' + florin + ') center center / cover no-repeat'
                                    }}></div>
                                    <div className="name">Florin</div>
                                    <div className="info">Bedrijfsleider<br/>arbeid & water</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section tvb-kwaliteitslabels">
                    <div className="container has-text-centered">
                        <h1 className="title">Onze kwaliteitslabels</h1>


                        <div className="glide">
                            <div className="glide__track" data-glide-el="track">
                                <ul className="glide__slides">
                                    <li className="glide__slide">
                                        <KwaliteitLabel title="Flandria" body="Het Belgische kwaliteitslabel voor groenten. Enkel producten van topkwaliteit, met een perfecte smaak en een onberispelijk uiterlijk krijgen deze erkenning toegekend. Flandriagroenten dienen kraakvers te zijn, duurzaam geteeld te worden met respect voor milieu en hygiëne en dienen opperbest te smaken." image={flandria}/>
                                    </li>
                                    {/* <li className="glide__slide">
                                        <KwaliteitLabel title="BelOrta" body="Wij zijn aangesloten bij BelOrta, de grootste groente- en fruitveiling van België en tevens Europees marktleider. Via deze afzetorganisatie worden jaarlijks 4.000 ton van onze tomaten verdeeld over retailers in binnen- en buitenland." image={belorta}/>
                                    </li> */}
                                    <li className="glide__slide">
                                        <KwaliteitLabel title="Global G.A.P." body="De internationale erkenning voor land- en tuinbouwbedrijven. In hun normen zijn eisen opgenomen voor voedselveiligheid, duurzaamheid en kwaliteit te garanderen. Deze criteria zijn samengesteld door experts uit de branche, producenten en retailers." image={globalgap}/>
                                    </li>
                                </ul>
                            </div>
                            <div className="glide__arrows" data-glide-el="controls">
                                <div className="arrow-left" data-glide-dir="<"></div>
                                <div className="arrow-right" data-glide-dir=">"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section tvb-kwaliteitslabels">
                    <div className="container has-text-centered">
                        <h1 className="title">Een kijkje in het bedrijf</h1>
                        <div>
                        <YoutubeBackground
                            videoId={'RcKYGtlVvh8'}
                            // onReady={(event) =>{
                            //     event.target.playVideo();
                            //     // Get the duration of the currently playing video
                            //     const videoDuration = event.target.getDuration();
                                
                            //     // When the video is playing, compare the total duration
                            //     // To the current passed time if it's below 2 and above 0,
                            //     // Return to the first frame (0) of the video
                            //     // This is needed to avoid the buffering at the end of the video
                            //     // Which displays a black screen + the YouTube loader
                            //     setInterval(function (){
                            //     const videoCurrentTime = event.target.getCurrentTime();
                            //     const timeDifference = videoDuration - videoCurrentTime;
                                
                            //     if (2 > timeDifference > 0) {
                            //         event.target.seekTo(0);
                            //     }
                            //     }, 1000);
                            // }}
                            nocookie={true} 
                            >
                            {/* YOUR CONTENT */}
                            </YoutubeBackground>

                        </div>
                    </div>
                </section>
                {/*<section className="tvb-fotos" style={{backgroundColor: '#FBF9F6', padding: '50px'}}>
                    <div className="container has-text-centered">
                        <h1 className="title" style={{color: '#0B3D2C'}}>Een kijkje in ons bedrijf</h1>
                        <Fotos urls={[foto4, foto5, foto6, foto7, foto8, foto9]} size="is-one-third"/>
                    </div>
                </section>*/}
            </div>
        )
    }
}

IndexPageTemplate.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

const IndexPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
      <Layout>
        <Helmet>
            <title>Tuinbouwbedrijf Van Bulck</title>
            <meta name="description" content="Een passie voor tomaten!" />
        </Helmet>
        <IndexPageTemplate
          title={post.frontmatter.title}
          titleImage={post.frontmatter.titleImage}
        />
      </Layout>
    );
  };

export default IndexPage;
// export const pageQuery = graphql`
//   query IndexQuery {
//     allMarkdownRemark(
//       sort: { order: DESC, fields: [frontmatter___date] },
//       filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
//     ) {
//       edges {
//         node {
//           excerpt(pruneLength: 400)
//           id
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             templateKey
//             date(formatString: "MMMM DD, YYYY")
//           }
//         }
//       }
//     }
//   }
// `
export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        titleImage {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
                original {
                    width
                    height
                }
            }
        }
      }
    }
  }
`
